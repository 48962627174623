.btn {
    border-radius: 0;
}
body{
    background: white !important;
}
.m-t-15{
    margin-top: 15px;
}

.btn + .btn {
    margin-left: 10px;
}
.question_p{
    font-weight: bold;
    font-size: 15px;
}
.question_p2{
    font-weight: bold;
    font-size: 13px;

}


.option {
    background-color: #cadaee;
    cursor: pointer;
    font-size: 20px;
    margin: 4px;
}

.option label {
    cursor: pointer;
    display: block;
    padding: 10px;
}

#quiz .options input[type=checkbox] {
    height: 22px;
    margin-right: 6px;
    width: 22px;
}

.cursor-pointer {
    cursor: pointer;
}

.result-question {
    background-color: #eee;
    margin: 4px;
    padding: 6px;
}

/* Create Quiz Styles */
.create-quiz .question {
    text-align: left;
}

.create-quiz input[type=text] {
    border: 1px solid #ddd;
    width: 95%;
}

.create-quiz .tb-quiz-name {
    background-color: #ccffcc;
    text-align: center;
}

.create-quiz .tb-desc {
    background-color: #fff;
}

.create-quiz .options > div {
    margin-left: 20px;
}

.create-quiz .options input[type=checkbox] {
    border: 1px solid #262626;
    display: inline-block;
    height: 20px;
    margin-top: 10px;
    margin-bottom: 0;
    padding: 0;
    width: 20px;
}
.homelist li{
    text-align: initial;
    font-style: italic;
    margin: auto;
    font-weight: bolder;
    font-size: medium;
}
.hash_back{
    padding: 20px;
    background: rgba(158, 158, 158, 0.47);
    margin-left: 50px;
    margin-right: 50px;

}
.form-check-input {
    margin-top: 0.2rem !important;

}
/*div.absolute {*/
/*    position: absolute;*/
/*    bottom: 10px;*/
/*    width: 50%;*/
/*    border: 3px solid #8AC007;*/
/*}*/
div.fixed {
    position: fixed;
    width: 50%;
    bottom: 10px;
    border: 3px solid #8AC007;
}
.logoImage{
    margin-top: 20px;
    width: 250px;
}
.form-check label{
    margin-left: 10px;
}
div.sticky {
    position: sticky;
    width:  100%;
    bottom: 10px;
}
div.parent {
    position: relative;

}
.hash_back ul{
    padding-inline-start: 20px;
    margin-top: 5px;
}
.hash_back li{
    font-weight: bold;

}
.section2label{
    font-size: 14px;
    color: black;
    font-style: italic;
}

.mheader{
    position: fixed;
    background: white;
    z-index: 9999999;
}
.question_image{
    float: right;
    width: 250px;
}
.question_image2{
    margin: auto;
    width: 250px;
}

@media only screen and (max-width: 480px) {
    h1, .h1 {
        font-size: 22px;
    }

    h2, .h2 {
        font-size: 20px;
    }

    h3, .h3 {
        font-size: 18px;
    }

    .option {
        font-size: 16px;
        padding: 6px;

    }
    .question_image{
        width: 100px;
    }
    .logoImage{

        width: 150px;
    }
}

@media (min-width: 1399px) {

    .container, .container-sm, .container-md, .container-lg, .container-xl {
        max-width: 960px !important;
    }
    .homelist li{

        width: 400px;

    }
    .table-bordered td {
        font-size: 20px;
    }
    .section2label{
        font-size: 17px;

    }
}
